.video-container {
    position: relative;
    width: 100%;
    padding-top: 49.3%;
    overflow: hidden;
}

  
.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-title {
    margin-top: 30px;
    margin-bottom:25px;
    font-family: "futura-pt", sans-serif;
    font-size: 40px;
    font-weight: normal;
    text-align: center;
}

.year {
    text-align: center;
    font-size: 13px;

}
.project-description {
    font-family: "futura-pt", sans-serif;
    font-size: 20px;

}

.role {
    font-family: "futura-pt", sans-serif;
    font-size: 20px;
    margin-top:10px;
    text-align: center;
}

.collab {
    font-family: "futura-pt", sans-serif;
    font-size: 17px;
    color: #FB88B4;
    font-weight: bold;
    margin-top: 7px;
    margin-bottom:0px;
    text-align: center;
}


.show-container {
    text-align: center; 
}

.show {
    font-family: "futura-pt", sans-serif;
    font-size: 17px;
    font-weight: regular;
    margin-top: 7px;
    margin-bottom:0px;
    font-style: italic;
	background-color: rgb(174, 234, 148, 0.8); 
    display: inline-block;
    
}




.underscore {
    text-decoration: underline;
    text-decoration-color: #FB88B4;
    text-decoration-thickness: 1.5px;
    text-underline-offset: 5px;
}

.caption {
    font-family: "futura-pt", sans-serif;
    margin-top: 5px;
    margin-bottom:15px;
    text-align: center;
    font-size:14px;
}


.section{
    font-size:24px;
    margin-top: 40px;
    margin-bottom:10px;
    font-family: neue-haas-grotesk-text, sans-serif;
}
.media-container {
    max-width: 100%; 
}

.photo {
    width: 100%;
    height: auto; 
    display: block; 
}