.tag {
    display: inline-block;
    background-color: white;
    color: #333;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 15px;
    font-size: 12px;
    border: 2px solid #FB88B4;
}
