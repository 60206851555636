.about-content-container {
    width: 93%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
  
.left-column {
    flex: 1;
    top: 0;
    align-self: flex-start;
    width: 41%; 
    max-width: 449px;
}

.right-column {
    margin-top: 0px;
    overflow-y: auto; 
}



.index-container {
    margin-top: 30px;
    margin-left: 0px;
    width: 100%;
    text-align: left;
}

.about-name-container {
    width: 100%;
    text-align: left;
    margin-bottom: 55px;
}
  

.link {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: 1.2em;
}

.masonry { 
    -webkit-column-count: 2;
    -moz-column-count:2;
    column-count: 2;
    -webkit-column-gap: 1em;
    -moz-column-gap: 1em;
    column-gap: 1em;
    margin: 1.5em;
    padding: 0;
    -moz-column-gap: 1.5em;
    -webkit-column-gap: 1.5em;
    column-gap: 1.5em;
    font-size: .85em;
    margin-top: 40px;
}
.item {
    display: inline-block;
    background: #fff;
    padding: 1em;
    margin: 0 0 1.5em;
    width: 100%;
	-webkit-transition: 1s ease all;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 2px 2px 4px 0 #FFEED9;
    border-radius: 5px;
}
.item img{max-width:100%;}

.item:hover {
	background-color: rgb(180, 212, 255, 0.8); 
}

.item_title {
    text-align: center;
    color: black;
    margin-top: 5px;
    margin-bottom: 5px;
}

.item_link {
    text-decoration: none;
}

.item_link:visited {
    text-decoration: none;
}
@media only screen and (max-width: 1200px) {
    .masonry {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
}


@media only screen and (min-width: 1201px) {
    .masonry {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}


@media (max-width: 768px) {
    .about-content-container {
        flex-direction: column; 
    }
    
    .left-column {
        width: 100%;
        text-align: center; 

    }
    h1{
       font-size: 40px; 
    }

    .right-column {
        width: 100%; 

    }

    .description {
        margin-top: 100px;
    }

    .footer {
        display: none;
    }
}