html {
    overflow-y: scroll;
    
}


::-webkit-scrollbar {
    width: 12px; 
}
  

:root {
    --scrollbar-track-color: #fff6eb; 
}
  
.dark-mode {
    --scrollbar-track-color: #000000; 
}
  
::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
}
 
::-webkit-scrollbar-thumb {
    background-color: #FB88B4; 
    border-radius: 10px; 
    border: 2px solid #f0f0f0; 
}
  

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(180, 212, 255)
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    width: 93%
}

.name-container {
  width: 100%;
  text-align: left;
}

.index-container {
    margin-top: 30px;
    margin-left: 0px;
    width: 100%;
    text-align: left;
}

h1 {
  font-family: 'Helvetica';
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 0px;
  font-size: 50px;
}

h3 {
  font-family: "futura-pt", sans-serif;
  margin-left: 0px;
  font-size: 20px;
}

.canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.home-link {
    text-decoration: none;
    color: black;
}

.home-link:visited {
    text-decoration: none;
}


.link {
    text-decoration: none;
    font-family: "futura-pt", sans-serif;
    margin-top: 5px;
    margin-left: 2.5px;
    font-size: 20px;
    display: block;
    font-weight: bold;
    max-width: fit-content;
}

.link:visited {
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
    text-decoration-color: #FB88B4;
    text-decoration-thickness: 5px;
    text-underline-offset: 5px;
}


.footer {
    margin-left: 0px;
    font-size: 12px;
    bottom: 0;
    position:fixed
}

