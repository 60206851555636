 /* About.css */
.about-content-container {
    width: 93%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
.left-column, .right-column {
    flex: 1;
}


.index-container {
    margin-top: 30px;
    margin-left: 0px;
    width: 100%;
    text-align: left;
}

.about-name-container {
    width: 100%;
    text-align: left;
    margin-bottom: 55px;
}

.description {
    margin-top: 300px;
    margin-bottom: 100px;
    margin-right:50px;
    font-family: "futura-pt", sans-serif;
    font-size: 20px;
    font-weight: normal;
}

.social {
    margin-top: 10px;
    margin-right: 50px;
    font-family: "futura-pt", sans-serif;
    font-size: 17px;
    font-weight: normal;
    color: inherit; 
    text-decoration: none; 
}

.social:visited {
    text-decoration: none;
    color: inherit; 
}

.social:hover {
    text-decoration: underline;
    text-decoration-color: #FB88B4;
    text-underline-offset: 5px;
    color: inherit; 
}

