#typing {
    display: inline;
    font-family: "futura-pt", sans-serif;
    font-size: 25px;
    margin: 0;
    
}
.cursor {
    display: inline-block;
    width: 2px;
    height: 1.2em;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.typing-container {
    margin-top: 0px;
}